import React from 'react';
import { Button, Flex, Input, Text } from '@chakra-ui/react';

import { AssetType } from 'entities/assets/model';
import { STYLE_CONFIG } from 'entities/assets/ui/asset-card';
import { ButtonType } from 'shared/analytics';

export interface Props {
	type: AssetType;
	w: number | string;
	h: number | string;
	handlePhotoUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ImageUpload: React.FC<Props> = ({
	type,
	w,
	h,
	handlePhotoUpload,
}) => {
	return (
		<Flex
			w="full"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			border="1px solid #eeeef2"
			borderRadius="10px"
			position="relative"
			overflow="hidden"
			{...STYLE_CONFIG[type]}
		>
			<Button name={ButtonType.UPLOAD_ASSET}>Upload Asset</Button>
			<Input
				type="file"
				id="file-input"
				accept="image/*"
				onChange={handlePhotoUpload}
				variant="file"
				value=""
			/>
			{w && h && w !== 'any' && h !== 'any' ? (
				<Text pt="20px" fontSize="12px" lineHeight="16px" color="#abacbe">
					Resolution of “{w}x{h}”
				</Text>
			) : null}
		</Flex>
	);
};
