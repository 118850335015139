/* eslint-disable prefer-destructuring */
import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Box, Divider, HStack, Portal, Text, Tooltip } from '@chakra-ui/react';
import createCounterPlugin from '@draft-js-plugins/counter';
import Editor from '@draft-js-plugins/editor';
import {
	ContentState,
	DraftHandleValue,
	EditorState,
	getDefaultKeyBinding,
	Modifier,
	RichUtils,
} from 'draft-js';
import { useStore } from 'effector-react';

import { scriptModel } from 'entities/script';
import { backgroundsStyleMap } from 'features/editor/background-color-picker/constants/backgrounds';
import { SnippetsSuggestions } from 'features/editor/snippets-suggestions';
import { FEATURES_TYPE } from 'pages/script-editor';
import { WarningIcon } from 'shared/icons';

import { SNIPPETS_FULL } from '../../constants/snippets';
import { applyEntityToTheInsertedCharacter } from '../../lib/apply-entity-to-inserted-character';
import { choicesCounter, countWords } from '../../lib/choices-counter';
import { filterSnippets } from '../../lib/filter-snippets';
import { insertDoubledCharacters } from '../../lib/insert-doubled-characters';
import { insertSnippet } from '../../lib/insert-snippet';
import styles from './styles.module.scss';
import { WarningTooltip } from './warning-tooltip';

import 'draft-js/dist/Draft.css';
import './styles.css';

const counterTheme = {
	counter: styles.counter,
	counterOverLimit: styles.counterOverLimit,
};

const counterPlugin = createCounterPlugin({ theme: counterTheme });

const { CustomCounter } = counterPlugin;

const plugins = [counterPlugin];

export interface DraftEditorProps {
	counterRef: React.RefObject<HTMLElement>;
	editorState: EditorState;
	setEditorState: Dispatch<SetStateAction<EditorState>>;
	withComments: boolean;
	isErrorBarOpened: boolean;
	features: FEATURES_TYPE | null;
}

const BASE_KEY_COMMAND_MAP: Record<string, string> = {
	'(': 'insert_)',
	'{': 'insert_}',
	'[': 'insert_]',
	'"': 'insert_"',
	$: 'insert_$',
};

const CTRL_KEY_COMMAND_MAP: Record<string, string> = {
	// b: 'BOLD',
	// B: 'BOLD',
	// i: 'ITALIC',
	// I: 'ITALIC',
	KeyI: 'ITALIC',
	KeyB: 'BOLD',
};

export const DraftEditor: React.FC<DraftEditorProps> = ({
	counterRef,
	editorState,
	setEditorState,
	withComments,
	isErrorBarOpened,
	features,
}) => {
	const ref = useRef<Editor>(null);
	const snippetsRef = useRef<HTMLDivElement | null>(null);

	const [prevWordsCount, setPrevWordsCount] = useState(0);
	// const [prevFreeChoicesCount, setPrevFreeChoicesCount] = useState(0);
	// const [prevPaidChoicesCount, setPrevPaidChoicesCount] = useState(0);
	const [index, setIndex] = useState(0);
	const [filteredSnippets, setFilteredSnippets] = useState<string[]>([]);

	const [filteredWord, setFilteredWord] = useState<string | null>(null);

	const linesWithErrors = useStore(scriptModel.$linesWithErrors);
	const linesWithWarnings = useStore(scriptModel.$linesWithWarnings);
	const isSyntaxError = useStore(scriptModel.$syntaxErrors);

	const hideBlockKeys = useStore(scriptModel.$hideBlockKeys);

	const handleSnippetModalShown = useCallback(
		(prevEditorState: EditorState) => {
			const selection = prevEditorState.getSelection();
			const currentContent = prevEditorState.getCurrentContent();

			const blockKey = selection.getStartKey();
			const currentBlock = currentContent.getBlockForKey(blockKey);

			if (!selection.isCollapsed() || !selection.getHasFocus()) {
				setFilteredWord(null);
				return null;
			}
			const plainText = currentBlock.getText();

			if (!plainText) {
				setFilteredWord(null);
				return null;
			}

			const lastLine = plainText
				.split('\n')
				.pop()
				?.slice(0, selection.getStartOffset());

			// * for symbol \v -
			if (!lastLine) {
				setFilteredWord(null);
				return null;
			}
			const lastWord = lastLine.split(' ').pop();

			if (
				!lastWord ||
				(!lastWord.includes('#') && !lastWord.includes('effect='))
			) {
				setFilteredWord(null);
				return null;
			}

			const lastSnippetWord = lastWord.includes('effect=')
				? lastWord
				: `#${lastWord.split('#').pop()}`;

			if (filterSnippets(lastSnippetWord)?.length) {
				setFilteredWord(lastSnippetWord);
				setFilteredSnippets(filterSnippets(lastSnippetWord));
			} else {
				setFilteredWord(null);
			}

			return null;
		},
		[],
	);

	const onChange = useCallback(
		(newEditorState: EditorState) => {
			const selection = newEditorState.getSelection();
			const prevSelection = editorState.getSelection();

			const anchorKey = selection.getAnchorKey();
			const prevAnchorKey = prevSelection.getAnchorKey();

			const isContentAction =
				editorState.getCurrentContent() !== newEditorState.getCurrentContent();
			const isAnchorChanged = anchorKey !== prevAnchorKey;

			// * we don't need to process snippets and comments if the content or anchor hasn't changed [OPTIMIZATION]
			if (!isContentAction && !isAnchorChanged) {
				setEditorState(newEditorState);
				return;
			}

			if (isContentAction) scriptModel.setIsScriptEdited(true);

			if (!withComments) {
				setEditorState(newEditorState);
				handleSnippetModalShown(newEditorState);
			}

			const contentState = newEditorState.getCurrentContent();

			const blockBefore = contentState.getBlockBefore(anchorKey);
			const currentBlock = contentState.getBlockForKey(anchorKey);
			const blockAfter = contentState.getBlockAfter(anchorKey);

			const currentBlockLength = currentBlock.getLength();
			const currentBlockStartOffset = selection.getStartOffset();

			const entityAtTheEndOfBlockBefore = blockBefore?.getEntityAt(
				blockBefore.getLength() - 2,
			);
			const entityBeforeInsertedChar = currentBlock?.getEntityAt(
				currentBlockStartOffset - 2,
			);
			const entityAfterInsertedChar = currentBlock?.getEntityAt(
				currentBlockStartOffset,
			);
			const entityAtTheStartOfBlockAfter = blockAfter?.getEntityAt(0);

			// * flow, when the selection is at the end of the line and the next line is under the same comment
			const firstFlow =
				entityBeforeInsertedChar &&
				entityAtTheStartOfBlockAfter &&
				entityBeforeInsertedChar === entityAtTheStartOfBlockAfter &&
				currentBlockStartOffset === currentBlockLength;

			// * flow, when the selection is at the beginning of the line, and the next character and the previous line are under the same comment
			const secondFlow =
				entityAtTheEndOfBlockBefore &&
				entityAfterInsertedChar &&
				entityAtTheEndOfBlockBefore === entityAfterInsertedChar &&
				currentBlockStartOffset === 1 &&
				currentBlockLength > 1;

			// * flow, when the selection is at the beginning of an empty line, and the next and previous lines are under the same comment
			const thirdFlow =
				entityAtTheEndOfBlockBefore &&
				entityAtTheStartOfBlockAfter &&
				entityAtTheEndOfBlockBefore === entityAtTheStartOfBlockAfter &&
				currentBlockStartOffset === 1 &&
				currentBlockLength === 1;

			if (firstFlow) {
				setEditorState(
					applyEntityToTheInsertedCharacter(
						newEditorState,
						selection,
						anchorKey,
						currentBlockStartOffset,
						entityBeforeInsertedChar,
					),
				);
			} else if (secondFlow || thirdFlow) {
				setEditorState(
					applyEntityToTheInsertedCharacter(
						newEditorState,
						selection,
						anchorKey,
						currentBlockStartOffset,
						entityAtTheEndOfBlockBefore,
					),
				);
			} else {
				setEditorState(newEditorState);
			}
			handleSnippetModalShown(newEditorState);
		},
		[editorState, handleSnippetModalShown, setEditorState, withComments],
	);

	const styleMap = {
		...backgroundsStyleMap,
	};

	const keyBindingFn = useCallback(
		// eslint-disable-next-line sonarjs/cognitive-complexity
		(event: React.KeyboardEvent) => {
			scriptModel.setIsDisabledControlButtons(true);

			const baseCommand = BASE_KEY_COMMAND_MAP[event.key];
			if (baseCommand && features?.isAutoBraces) return baseCommand;

			const ctrlCommand = CTRL_KEY_COMMAND_MAP[event.code];
			if (ctrlCommand && (event.metaKey || event.ctrlKey)) {
				scriptModel.setIsScriptEdited(true);
				return ctrlCommand;
			}

			if (event.key === 'ArrowDown' && filteredWord) {
				event.preventDefault();
				return 'arrowDown';
			}
			if (event.key === 'ArrowUp' && filteredWord) {
				event.preventDefault();
				return 'arrowUp';
			}
			if (event.key === 'Escape' || (event.key === 'Tab' && filteredWord)) {
				event.preventDefault();
				return 'closeSnippets';
			}
			if (event.key === 'Enter' && filteredWord) {
				event.preventDefault();
				return 'selectSnippet';
			}

			return getDefaultKeyBinding(event);
		},
		[filteredWord, features?.isAutoBraces],
	);

	const handleKeyCommand = useCallback(
		// eslint-disable-next-line sonarjs/cognitive-complexity
		(command: string) => {
			switch (command) {
				case 'BOLD':
					setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
					return 'handled';
				case 'ITALIC':
					setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
					return 'handled';
				case 'insert_)':
					setEditorState(insertDoubledCharacters(editorState, '(', ')'));
					return 'handled';
				case 'insert_}':
					setEditorState(insertDoubledCharacters(editorState, '{', '}'));
					return 'handled';
				case 'insert_]':
					setEditorState(insertDoubledCharacters(editorState, '[', ']'));
					return 'handled';
				case 'insert_$':
					setEditorState(insertDoubledCharacters(editorState, '$', '$'));
					return 'handled';
				case 'insert_"':
					setEditorState(insertDoubledCharacters(editorState, '"', '"'));
					return 'handled';
				case 'arrowDown': {
					if (filteredWord) {
						const prevIndex =
							index >= filteredSnippets.length - 1 ? 0 : index + 1;
						setIndex(prevIndex);
					}
					return 'handled';
				}
				case 'arrowUp': {
					if (filteredWord) {
						const nextIndex =
							index <= 0 ? filteredSnippets.length - 1 : index - 1;
						setIndex(nextIndex);
					}
					return 'handled';
				}
				case 'closeSnippets':
					if (filteredWord) {
						setFilteredWord(null);
					}
					return 'handled';
				case 'selectSnippet':
					if (filteredWord) {
						let snippet = SNIPPETS_FULL[filteredSnippets[index]];
						if (typeof snippet === 'object') {
							snippet = snippet[0];
						}
						const snippetString =
							typeof snippet === 'function' ? snippet([]) : snippet;
						const newEditorState = insertSnippet(
							editorState,
							snippetString as string,
							filteredWord,
						);
						setEditorState(newEditorState);
						setFilteredWord(null);
						return 'handled';
					}
					return 'not-handled';
				default:
					return 'not-handled';
			}
		},
		[setEditorState, editorState, filteredWord, index, filteredSnippets],
	);

	const handlePastedText = useCallback(
		(text: string): DraftHandleValue => {
			const pastedBlocks = ContentState.createFromText(text).getBlockMap();
			const newState = Modifier.replaceWithFragment(
				editorState.getCurrentContent(),
				editorState.getSelection(),
				pastedBlocks,
			);
			onChange(EditorState.push(editorState, newState, 'insert-fragment'));
			return 'handled';
		},
		[editorState, onChange],
	);

	const editorSelection = useMemo(
		() => editorState.getSelection().getFocusKey(),
		[editorState],
	);

	useEffect(() => {
		if (filteredWord && filteredSnippets.length > 0 && snippetsRef.current) {
			const el = snippetsRef.current;
			const currentContent = editorState.getCurrentContent();
			const selection = editorState.getSelection();
			const focusOffset = selection.getFocusOffset();

			const currentBlock = currentContent.getBlockForKey(
				selection.getFocusKey(),
			);

			const textBlock = currentBlock.getText().slice(0, focusOffset);

			let textWidth = 0;

			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');

			if (context) {
				// * get text width in pixels from string
				context.font = 'normal 14px poppins';
				textWidth = context?.measureText(textBlock)?.width;
			}

			const rect = document
				.querySelector(`div[data-offset-key="${currentBlock.getKey()}-0-0"]`)
				?.getBoundingClientRect();
			const bottomOffset = window.innerHeight - (rect?.top || 0);

			if (bottomOffset < 248) {
				el.style.top = `${
					(rect?.top || 0) + window.pageYOffset - (el?.clientHeight || 0)
				}px`;
			} else {
				el.style.top = `${(rect?.top || 0) + window.pageYOffset + 24}px`;
			}
			el.style.left = `${(textWidth || 0 + window.pageXOffset) + 47}px`;
		}
	}, [editorState, filteredSnippets.length, filteredWord]);

	useEffect(() => {
		const blocks = document.querySelectorAll('div[data-block="true"]');

		if (linesWithErrors) {
			blocks.forEach((block, idx) => {
				if (linesWithErrors.includes(idx)) {
					block.classList.add('error-block');
				} else {
					block.classList.remove('error-block');
				}
			});
		}
		if (linesWithWarnings) {
			blocks.forEach((block, idx) => {
				if (linesWithWarnings.includes(idx)) {
					block.classList.add('warning-block');
				} else {
					block.classList.remove('warning-block');
				}
			});
		}
	}, [editorState, linesWithErrors, linesWithWarnings]);

	useEffect(() => {
		document.querySelector('.line-active')?.classList.remove('line-active');

		const block = document.querySelector(
			`div[data-offset-key="${editorSelection}-0-0"]`,
		);

		block?.classList.add(
			features?.isAutoHighlight ? 'line-active' : '.line-active-default',
		);
	}, [editorSelection, features?.isAutoHighlight]);

	useEffect(() => {
		const texts = document.querySelectorAll('span[data-text="true"]');
		texts.forEach((text) => {
			if (!features?.isDecorator) {
				text.classList.add('editor-default');
			} else {
				text.classList.remove('editor-default');
			}
		});
	}, [editorState, features?.isDecorator]);

	useEffect(() => {
		hideBlockKeys.forEach(({ blockKeysIn, isOpen }) => {
			blockKeysIn.forEach((k: any) => {
				const block = document.querySelector(
					`div[data-block="true"][data-offset-key="${k}-0-0"]`,
				);
				if (!features?.isCodeFolding) {
					block?.classList.remove('hide');
					return;
				}
				if (isOpen) {
					block?.classList.remove('hide');
				} else {
					block?.classList.add('hide');
				}
			});
		});
	}, [hideBlockKeys, features?.isCodeFolding]);

	return (
		<Box
			width={isErrorBarOpened ? 'calc(100% - 256px)' : '100%'}
			onClick={() => {
				ref?.current?.focus();
			}}
		>
			<Editor
				editorKey="editor"
				editorState={editorState}
				onChange={onChange}
				plugins={plugins}
				customStyleMap={styleMap}
				keyBindingFn={keyBindingFn}
				handleKeyCommand={handleKeyCommand}
				handlePastedText={handlePastedText}
				ref={ref}
			/>
			{features?.isSnippets && filteredWord && filteredSnippets.length && (
				<SnippetsSuggestions
					index={index}
					containerRef={snippetsRef}
					suggestions={filteredSnippets}
					onSnippetSelect={(inx, subInx, variants) => {
						let snippet = SNIPPETS_FULL[filteredSnippets[inx]];
						if (typeof snippet === 'object') {
							snippet = snippet[subInx || 0];
						}
						const snippetString =
							typeof snippet === 'function'
								? snippet(variants as string[])
								: snippet;
						const newEditorState = insertSnippet(
							editorState,
							snippetString,
							filteredWord,
						);
						setEditorState(newEditorState);
						setFilteredWord(null);
					}}
				/>
			)}

			<Portal containerRef={counterRef}>
				<HStack zIndex={100} alignItems="center" marginRight={10} spacing={5}>
					<HStack alignItems="center">
						<Text fontSize="14px" color="#808192" fontWeight={400}>
							Words:
						</Text>
						<CustomCounter
							countFunction={(text) => {
								const res = countWords(text);
								if (res === 0) {
									return prevWordsCount;
								}
								setPrevWordsCount(res);
								return res;
							}}
							className={styles.counterWord}
						/>
						{isSyntaxError && (
							<Tooltip
								label={<WarningTooltip />}
								maxW="224px"
								fontSize="12px"
								p={0}
								lineHeight="16px"
								backgroundColor="#FDFDFD"
								placement="bottom-start"
							>
								<WarningIcon color="#ED0000" w={6} h={6} />
							</Tooltip>
						)}
					</HStack>
					<Divider orientation="vertical" height="20px" />
					<HStack alignItems="center">
						<Text fontSize="14px" color="#808192" fontWeight={400}>
							Free Choices:
						</Text>
						<CustomCounter
							countFunction={(text) => {
								const res = choicesCounter(text);
								// if (res.free === 0) {
								// 	return prevFreeChoicesCount;
								// }
								// setPrevFreeChoicesCount(res.free);
								return res.free;
							}}
							limit={50}
							className={styles.counterValue}
						/>
						<Text fontSize="8px" margin="0 3px">
							/
						</Text>
						<Text className={styles.counterMax}>50</Text>
					</HStack>
					<HStack alignItems="center">
						<Text fontSize="14px" color="#808192" fontWeight={400}>
							Paid Choices:
						</Text>
						<CustomCounter
							countFunction={(text) => {
								const res = choicesCounter(text);
								// if (res.paid === 0) {
								// 	return prevPaidChoicesCount;
								// }
								// setPrevPaidChoicesCount(res.paid);
								return res.paid;
							}}
							limit={50}
							className={styles.counterValue}
						/>
						<Text fontSize="8px" margin="0 3px">
							/
						</Text>
						<Text className={styles.counterMax}>50</Text>
					</HStack>
				</HStack>
			</Portal>
		</Box>
	);
};
