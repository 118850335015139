import React, { useCallback, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { ImageReviewBlock } from 'features/upload-file/ui/image-review-block';
import { ImageUploadBlock } from 'features/upload-file/ui/image-upload-block';
import { FILENAME_REGEX } from 'shared/utils/regex/filename';

import { useAppToast } from '../../../shared/hooks/toast';
import { getFilenameFromURL } from '../../../shared/utils/get-filename-from-url';

export interface Props {
	label: string;
	emotions: string[];
	onChange: (emotions: string[]) => void;
	error?: string[] | string;
	multiple?: boolean;
}

export const EmotionsReview: React.FC<Props> = ({
	label,
	emotions,
	onChange,
	error,
	multiple,
}) => {
	const toast = useAppToast();
	const [newEmotions, setNewEmotions] = useState(emotions);

	const updateEmotions = useCallback(
		(emotionsList: string[]) => {
			const defaultEmotions = emotionsList.filter((em) =>
				em.includes('_default'),
			);
			// filter emotions to set default emotion first
			const filteredEmotions = [
				...defaultEmotions,
				...emotionsList.filter((em) => !em.includes('_default')),
			].filter(Boolean);

			setNewEmotions(filteredEmotions);
			onChange(filteredEmotions);
		},
		[onChange],
	);

	const findUniqueEmotions = useCallback(
		(links: string[]) => {
			const uniqueEmotions: string[] = [];

			links.forEach((link) => {
				const filename = getFilenameFromURL(link);
				const res = newEmotions.find((em) => em.includes(filename || ''));
				if (res) {
					toast({
						title: `The ${filename} emotion is already added`,
						status: 'error',
					});
				} else uniqueEmotions.push(link);
			});

			return uniqueEmotions;
		},
		[newEmotions, toast],
	);

	const onDelete = useCallback(
		(index: number) => {
			const result = newEmotions.filter((e, i) => i !== index);

			setNewEmotions(result);
			onChange(result);
		},
		[newEmotions, onChange],
	);

	const onAdd = useCallback(
		(link: string) => {
			const uniqueEmotions = findUniqueEmotions([link]);

			if (uniqueEmotions.length) {
				const result = [...newEmotions, ...uniqueEmotions];
				updateEmotions(result);
			}
		},
		[findUniqueEmotions, newEmotions, updateEmotions],
	);

	// to add multiple emotions
	const onMultipleAdd = useCallback(
		(links: string[]) => {
			const uniqueEmotions = findUniqueEmotions(links);
			if (uniqueEmotions.length) {
				const result = [...newEmotions, ...uniqueEmotions];
				updateEmotions(result);
			}
		},
		[findUniqueEmotions, newEmotions, updateEmotions],
	);

	const onUpdate = useCallback(
		(prevLink: string, link: string) => {
			const uniqueEmotions = findUniqueEmotions([link]);

			if (uniqueEmotions.length) {
				const result = newEmotions.filter((e) => e !== prevLink);
				const newResult = [...result, ...uniqueEmotions];
				updateEmotions(newResult);
			}
		},
		[findUniqueEmotions, newEmotions, updateEmotions],
	);

	return (
		<Box p="20px 0" borderBottom="1px solid #eeeef2">
			<Text mb="7px" fontSize="14px" lineHeight="114%">
				{label}
			</Text>
			<Flex gap="20px" mt="8px" flexWrap="wrap">
				{emotions.length > 0 &&
					emotions.map((e, index) => (
						<ImageReviewBlock
							key={e}
							imageLink={e}
							imageName={(e.match(FILENAME_REGEX) || [])[0]}
							onDelete={onDelete}
							setValue={onUpdate}
							index={index}
							customStyle={{ h: '182px' }}
							error={error && error[index]}
						/>
					))}
				<ImageUploadBlock
					setValue={(link: string) => onAdd(link)}
					onMultipleAdd={onMultipleAdd}
					accept="image/*"
					imageConfig={{ w: 731, h: 1661 }} // {{ w: 731, h: 1661 }}
					multiple={multiple}
				/>
			</Flex>
			{error && (
				<Text
					pt={2}
					fontWeight={600}
					fontSize="13px"
					lineHeight="17px"
					color="#ff218c"
				>
					{error}
				</Text>
			)}
		</Box>
	);
};
